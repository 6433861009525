<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Applied Documents</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<b-button v-b-modal.applied-docs-modal variant="primary" class="btn-sm">Add Request</b-button>
						</div>
					</template>
					<template v-slot:body>
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchRequests()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchRequests()" />
									</label>
								</div>							
								<div class="div-search mr-2">
									<select class="form-control" v-model="status" @change="fetchRequests()">
										<option value="">All</option>
										<option value="1">Pending</option>
										<option value="2">Completed</option>
									</select>
								</div>
							</div>
						</div>
						<!----- Listing length and search ends ----->

						<!--- Listing starts ----->
						<div class="table-responsive">
							<b-button variant="primary" class="btn mt-2 mb-2 mr-1 btn-sm" @click="generatePDF()" v-if="Boolean(applied_docs.length)">Download PDF</b-button>
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>									
									<tr>
										<th width="15%">Student Name</th>
										<th width="10%">Enrollment number</th>
										<th width="15%">Centre</th>
										<th width="15%">Applied Document</th>			
										<th width="15%">Request Added On</th>
										<th width="15%">Status</th>
										<th width="15%">Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(applied_docs.length)">
									<tr v-for="applied_doc in applied_docs" :key="applied_doc.id">
										<td>{{applied_doc.name}}</td>
										<td>{{applied_doc.enrollment_number}}</td>
										<td>{{applied_doc.centre}}</td>
										<td>{{applied_doc.document_name}}</td>								
										<td>{{ applied_doc.received_time }}</td>
										<td>{{applied_doc.status}} <br> {{applied_doc.completed_on}}</td>
										<td>
											<b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="changeStatus(applied_doc)">Change Status</b-button>
											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm"  @click="deleteDocument(applied_doc.id)">Delete</b-button>
											
										</td>
									</tr>									
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="7" class="text-center">No data found</td>
									</tr>
								</tbody>								
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="applied-docs-modal" id="applied-docs-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addDocument">
								
								<div class="form-group col-12 ">
									<label>Student <span class="required">*</span></label>
									<div class="search-select">
										<span class="search-close" v-if="visible" @click="closeSearch"><i class="dripicons dripicons-cross"></i></span>
											
										<b-form-input v-model="student_keyword"  @keyup="fetchStudents()"  v-bind:class="{ 'is-invalid': Boolean(errors.student_id) }" autocomplete="off" placeholder="Search here..."></b-form-input>
										<div v-for="(val, index) in errors.student_id" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>	
										<b-form-select v-if="visible && Boolean(students.length)" v-model="applied_doc.student_id" :select-size="students.length < 10 ? (students.length <= 1 ? 2 : students.length) : 10" @change="setStudent">
										  <b-form-select-option v-for="student in students" :key="student.id" :value="student.id">{{ student.name }} - {{ student.enrollment_number }} </b-form-select-option>
										</b-form-select>

										<b-form-select v-if="visible && !Boolean(students.length)" v-model="student_id" :select-size="2">						
											<b-form-select-option :value="0" disabled>No data results...</b-form-select-option>
										</b-form-select>
									</div>
								</div>
								<b-col md="12" class="form-group">
									<label class="control-label">Applied Document<span class="required">*</span></label>									
									<b-form-select v-model="applied_doc.document_type" v-bind:class="{ 'is-invalid': Boolean(errors.document_type) }">
										<template #first>
										<b-form-select-option value="" disabled>-- Select type --</b-form-select-option>
										</template>
										<b-form-select-option v-for="type in types" :key="type.id" :value="type.id">{{ type.document_name }}</b-form-select-option>
									</b-form-select>
										
									<div v-for="(val, index) in errors.document_type" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
								
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('applied-docs-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchRequests(page.url + '&limit=' + listing_length + '&keyword=' + keyword + '&status=' + status)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "AppliedDocuments",
	data() {
		return {
			applied_docs: [],
			students: [],
			types : [],
			applied_doc: {
				id: null,
				student_id: null,
			   	document_type: "",
			},
			
			modal_title: "Add Request",
			button_title: "Save",
			pagination: {},
			edit: false,
			applied_doc_status: {
				id: "",
			},
			keyword: "",
			showsearch: false,
			listing_length: 10,
			status: '',
			token: "",
			errors: {},
			activate: false,
         	deactivate: false,
         	visible: false,
			student_keyword: '',
			student_id: 0,
			delete: false,
		};
	},
	mounted() {
			
	},

	created() {
		this.fetchRequests();
		this.fetchTypes();
	},

		methods: {
		    
		    closeSearch() {
				this.visible = false;
				
				this.student_keyword = '';
				this.student_id = 0;
			},

			fetchRequests(page_url = null) {
				let url = "/api/applied_documents?limit=" + this.listing_length + "&keyword=" + this.keyword + "&status=" + this.status;
				// alert(this.listing_length);
				page_url = page_url || url;

				this.active_page_url = page_url;

				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.applied_docs = responseData.data;
						this.pagination = responseData.links;
						this.visible = false;
						console.log(this.applied_docs);
					})
					.catch((err) => console.log(err));
			},
			
			fetchStudents() {
				let url = "/api/applied_documents/students?keyword=" + this.student_keyword;
				this.getToken();
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data;
						this.students = responseData.data;
						this.visible = true;
					})
					.catch((err) => console.log(err));
			},

			fetchTypes() {
				let url = "/api/applied_documents/types";
				this.getToken();
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data;
						this.types = responseData.data;
						this.visible = false;
					})
					.catch((err) => console.log(err));
			},

			setStudent() {
				this.student_keyword = event.target.options[event.target.options.selectedIndex].label;
				this.visible = false;
			},

			getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

			addDocument() {
				this.getToken();
				let formData = new FormData();
				formData.append("student_id", this.applied_doc.student_id);
				formData.append("document_type", this.applied_doc.document_type);
				
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				
				url = "/api/applied_documents/add";
				this.active_page_url = null;
			 
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("applied-docs-modal");
						this.fetchRequests(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);

						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},

			clearForm() {				
				this.edit = false;
				this.modal_title = "Add Request";
				this.button_title = "Add";
                this.errors = {}; 
				
				this.applied_doc.student_id = "";
				this.applied_doc.document_type = "";
				this.visible = false;
				this.student_keyword = '';
				this.student_id = 0;
			},
		
			deleteDocument(document_id) {
				this.delete = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this document request?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		        .then(value => {
		            this.delete = value
		            if(this.delete){
						this.getToken();
						this.$axios
							.get("/api/applied_documents/delete?request_id=" + document_id, { headers: { Authorization: "Bearer " + this.token } })
							.then((response) => {
								if (response.data.status) {
									this.$vToastify.success(response.data.message);
								} else {
									this.$vToastify.error(response.data.message);
								}
								this.fetchRequests(this.active_page_url);
							})
							.catch((err) => console.log(err));
					}
	         	})
	         	.catch(err => {
	            	console.log(err);
	         	})     
			},

			changeStatus(document) {
				this.deactivate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to complete this document request?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		         .then(value => {
		            this.deactivate = value
		            if(this.deactivate){
						this.applied_doc_status.id = document.id;
						this.updateDocumentStatus();
					}
         		})
         		.catch(err => {
           			console.log(err);
         		})
			},
			updateDocumentStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/applied_documents/status?request_id=" + this.applied_doc_status.id, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.fetchRequests(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			generatePDF() {
				this.getToken();
				let page_url = '/api/applied_documents/download?limit=' + this.listing_length + '&keyword=' + this.keyword + '&status=' + this.status;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token }, responseType: "arraybuffer", })
					.then((response) => {
						// alert(response);
						console.log(response);
						let blob = new Blob([response.data], { type: "application/pdf" });
						let link = document.createElement("a");
						link.href = window.URL.createObjectURL(blob);
						link.download = "studentAppliedDocuments.pdf";
						// document.body.appendChild(link);
						link.click();
						
					})
					.catch((err) => console.log(err));
			},
		},	
};
</script>
